<template>
  <div>
    <v-fade-transition mode="in" leave-absolute group>
      <div v-if="rotasCalculadas.length===0" key="input">
        <v-card>
          <v-card-title>
            Simular
          </v-card-title>
          <v-card-text class="pl-0">
            <v-form
                ref="form"
                lazy-validation
            >
              <v-timeline dense>
                <v-timeline-item
                    fill-dot
                    icon="mdi-circle-medium"
                    small
                >
                  <v-row dense>
                    <v-col cols="auto" class="my-auto pr-4">
                      <span class="title">A</span>
                    </v-col>
                    <v-col>
                      <v-row dense>
                        <v-col cols="12" lg="3">
                          <EmpresasSelect
                              v-model="form.empresa"
                              obrigatorio
                              persistent-placeholder
                              return-object
                              svi
                              @input="resetRotasCalculadas()"
                          />
                        </v-col>
                        <v-col cols="12" lg="4">
                          <ProducoesSelect
                              v-model="form.producao"
                              :empresa="form.empresa"
                              obrigatorio
                              persistent-placeholder
                              return-object
                              svi
                              @input="resetRotasCalculadas()"
                          />
                        </v-col>
                        <v-col v-if="form.errors && form.errors['producao_id']" cols="12">
                          <v-alert type="error" dense outlined>
                            {{ form.errors['producao_id'][0] }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto" class="my-auto">
                    </v-col>
                  </v-row>
                  <v-divider class="mt-3 mb-0"/>
                </v-timeline-item>
                <v-slide-y-transition mode="in" group leave-absolute>
                  <v-timeline-item
                      v-for="(item, i) in form.rotas"
                      fill-dot
                      :icon="form.rotas.length===(i+1)?'mdi-map-marker':'mdi-circle-medium'"
                      :color="form.rotas.length===(i+1)?'red darken-2':'primary'"
                      small
                      :key="i"
                  >
                    <v-row dense>
                      <v-col cols="auto" class="my-auto pr-4">
                        <span class="title">{{ item.letra }}</span>
                      </v-col>
                      <v-col>
                        <v-row dense>
                          <v-col cols="12" lg="3">
                            <EstadoSelect
                                v-model="item.uf"
                                obrigatorio
                                persistent-placeholder
                                return-object
                                @input="resetRotasCalculadas()"
                            ></EstadoSelect>
                          </v-col>
                          <v-col cols="12" lg="4">
                            <CidadeSelect
                                v-model="item.cidade"
                                :estado="item.uf"
                                obrigatorio
                                persistent-placeholder
                                return-object
                                @input="resetRotasCalculadas()"
                            ></CidadeSelect>
                          </v-col>
                          <v-col cols="12" lg="3">
                            <v-text-field
                                v-model="item.coordenadas"
                                dense
                                label="Coordenada"
                                outlined
                                persistent-placeholder
                                persistent-hint
                                placeholder="Ex: -21.185197813641781, -47.813641185197364"
                                :rules="coordinateRule"
                                @input="resetRotasCalculadas()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="2">
                            <v-currency-field
                                v-model="item.quantidade"
                                :decimal-length="0"
                                dense
                                label="Quantidade (m²) *"
                                locale="pt-BR"
                                outlined
                                persistent-placeholder
                                :rules="requiredRule"
                                value-as-integer
                                @input="resetRotasCalculadas()"
                            ></v-currency-field>
                          </v-col>
                          <v-col v-if="form.errors && form.errors['rotas.'+i+'.tabela_frete']" cols="12">
                            <v-alert type="error" dense outlined>
                              {{ form.errors['rotas.' + i + '.tabela_frete'] }}
                            </v-alert>
                          </v-col>
                          <v-col v-if="form.errors && form.errors['rotas.'+i+'.rota']" cols="12">
                            <v-alert type="error" dense outlined>
                              {{ form.errors['rotas.' + i + '.rota'] }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="auto" class="my-auto">
                        <template v-if="form.rotas.length>1">
                          <v-icon
                              @click="moverRota(i, i-1)"
                              :disabled="i===0"
                          >
                            mdi-arrow-up-thin
                          </v-icon>
                          <v-icon
                              @click="moverRota(i, i+1)"
                              :disabled="form.rotas.length===i+1"
                          >
                            mdi-arrow-down-thin
                          </v-icon>
                          <v-icon
                              @click="removerRota(i)"
                          >
                            mdi-close-circle-outline
                          </v-icon>
                        </template>
                      </v-col>
                    </v-row>
                    <v-divider v-if="i<(form.rotas.length-1)" class="mt-3 mb-0" />
                  </v-timeline-item>
                </v-slide-y-transition>
              </v-timeline>
            </v-form>
          </v-card-text>
          <v-card-actions class="ml-5">
            <v-btn
                color="primary"
                text
                @click="adicionarRota"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              adicionar destino
            </v-btn>
          </v-card-actions>
          <v-card-text v-if="form.errors && form.errors['valor']">
            <v-alert type="error" dense outlined>
              {{ form.errors['valor'] }}
            </v-alert>
          </v-card-text>
        </v-card>
        <v-card class="mt-4">
          <v-card-actions>
            <v-spacer />
            <v-btn
                color="primary"
                @click="$router.push('/fretes/simulador-fretes')"
            >
              <v-icon left>mdi-cancel</v-icon>
              cancelar
            </v-btn>
            <v-btn
                color="primary"
                :loading="loading"
                @click="simularRota"
            >
              <v-icon left>
                mdi-map-marker-path
              </v-icon>
              simular
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <detalhamento-rotas
          v-if="rotasCalculadas.length>0"
          :items="rotasCalculadas"
          key="view"
          @descartarSimulacao="descartarSimulacao"
          @editarSimulacao="editarSimulacao"
      />
    </v-fade-transition>
    <custom-snackbar ref="snackbar" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import DetalhamentoRotas from '@/view/pages/fretes/simulador-fretes/components/DetalhamentoRotas'

export default {
  components: {
    DetalhamentoRotas,
  },

  data: () => ({
    loading: false,
    form: {
      empresa: null,
      producao: null,
      rotas: [],
      errors: [],
    },
    rotasCalculadas: [],
    defaultItem: {
      id: null,
      item: null,
      letra: null,
      uf: null,
      cidade: null,
      coordenadas: null,
      quantidade: null,
    },
    requiredRule: [
      (v) => !!v || 'O campo é obrigatório',
    ],
    coordinateRule: [
      (v) => {
        if (v) {
          const splitedV = v.split(', ')
          const errMessage = 'Coordenada inválida'

          if (typeof splitedV[0] === 'undefined' || typeof splitedV[1] === 'undefined') {
            return errMessage
          }

          if (
            !/^(\+|-)?(?:90(?:(?:\.0{1,15})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(v.split(', ')[0]) ||
            !/^(\+|-)?(?:180(?:(?:\.0{1,15})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,15})?))$/.test(v.split(', ')[1])
          ) {
            return errMessage
          }
        }

        return true
      },
    ],
  }),

  methods: {
    editarSimulacao () {
      this.rotasCalculadas = []
    },
    descartarSimulacao () {
      this.$router.push('/fretes/simulador-fretes/novo')

      this.form = {
        empresa: null,
        producao: null,
        rotas: [],
        errors: [],
      }

      this.rotasCalculadas = []

      this.adicionarRota()
    },
    formOutput () {
      const data = {}
      const rotasCalculadas = this.rotasCalculadas
      data.empresa_id = this.form.producao.empresa_id
      data.producao_id = this.form.producao.producao_id
      data.rotas = []

      this.form.rotas.forEach((item, index) => {
        data.rotas.push({
          id: item.id,
          item: item.item,
          cidade_id: item.cidade.id,
          uf_id: item.uf.id,
          coordenadas: item.coordenadas,
          quantidade: item.quantidade,
          simulacao: rotasCalculadas[index] ? rotasCalculadas[index].simulacao : null,
        })
      })

      return data
    },
    adicionarRota () {
      const defaultItem = JSON.parse(JSON.stringify(this.defaultItem))

      defaultItem.id = 0
      defaultItem.item = this.form.rotas.length + 1

      this.form.rotas.push(defaultItem)

      this.resetRotasCalculadas()

      this.ordernarLetras()
    },

    removerRota (i) {
      this.form.rotas.splice(i, 1)

      this.resetRotasCalculadas()

      this.ordernarLetras()
    },

    moverRota (de, para) {
      var item = this.form.rotas[de]

      this.form.rotas.splice(de, 1)
      this.form.rotas.splice(para, 0, item)

      this.resetRotasCalculadas()

      this.ordernarLetras()
    },

    ordernarLetras () {
      this.form.rotas.forEach((item, i) => {
        item.item = i + 1
        item.letra = String.fromCharCode('a'.charCodeAt(0) + (i + 1)).toUpperCase()
      })
    },

    resetRotasCalculadas () {
      this.rotasCalculadas = []
    },

    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async pegarSimulacao () {
      try {
        const id = this.$route.params.id
        let result = null

        if (id === 'novo') {
          this.adicionarRota()
        } else {
          result = (await ApiService.get(`fretes/simulador-fretes/${id}`)).data

          this.form.empresa = {
            id: result.producao.svi.id,
            nome: result.producao.svi.nome,
          }

          await this.sleep(200)

          this.form.producao = {
            empresa_id: result.producao.empresa_id,
            endereco_id: result.producao.endereco_id,
            id: result.producao.empresa_id + String(result.producao.id).padStart(5, '0'),
            nome: result.producao.sigla,
            producao_id: result.producao.id,
            svi: result.producao.usu_codsvi,
          }

          const rotas = []

          result.simulador_frete_rotas.forEach(function (item) {
            rotas.push({
              id: 0,
              item: item.item,
              cidade: {
                id: item.cidade_id,
              },
              uf: {
                id: item.uf_id,
              },
              coordenadas: item.coordenadas,
              quantidade: item.quantidade,
              simulacao: [],
            })
          })

          this.form.rotas = rotas

          this.ordernarLetras()
        }
      } catch (e) {
        this.$refs.snackbar.show(
          'Desculpe, algo deu errado',
          'Servidor não conseguiu responder a sua solicitação',
          'danger',
          3000,
        )
      }
    },

    async simularRota () {
      this.form.errors = []

      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true

      try {
        const result = (await ApiService.post('fretes/simulador-fretes/simular', this.formOutput())).data.data

        const valorTotalFreteM2 = function (items) {
          let sum = 0

          items.forEach((item) => {
            sum += item.valorTotalM2
          })

          return sum
        }

        // validação para não deixar realizar simulação com valor menor que R$ 300,00
        if (valorTotalFreteM2(result) < 300) {
          this.form.errors = {
            valor: 'Não é possível simular o frete devido a distância da rota',
          }

          this.$refs.snackbar.show(
            'Desculpe, algo deu errado',
            'Verifique as informações e tente novamente',
            'danger',
            3000,
          )
        } else {
          const rotasCalculadas = []

          result.forEach((item) => {
            rotasCalculadas.push({
              simulacao: item,
            })
          })

          this.rotasCalculadas = rotasCalculadas

          await ApiService.post('fretes/simulador-fretes', this.formOutput())

          this.$refs.snackbar.show('Deu tudo certo!', 'Sua simulação foi realizada com sucesso.', 'success', 3000, false)
        }
      } catch (e) {
        if (e.response && e.response.data.errors) {
          this.form.errors = e.response.data.errors

          this.$refs.snackbar.show(
            'Desculpe, algo deu errado',
            'Verifique as informações e tente novamente',
            'danger',
            3000,
          )
        } else {
          this.$refs.snackbar.show(
            'Desculpe, algo deu errado',
            'Servidor não conseguiu responder a sua solicitação',
            'danger',
            3000,
          )
        }
      }

      this.loading = false
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes', route: 'simulador-fretes' },
      { title: 'Simulador de Fretes' },
    ])

    this.pegarSimulacao()
  },
}
</script>
