import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,[_vm._v(" Simulação ")]),_c(VCardText,[_c('detalhamento-rotas-table',{attrs:{"items":_vm.items}})],1)],1),_c(VCard,{staticClass:"mt-4"},[_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push('/fretes/simulador-fretes')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" cancelar ")],1),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('editarSimulacao')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" editar ")],1),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('descartarSimulacao')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" novo ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }