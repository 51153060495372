<template>
  <div>
  <v-card>
    <v-card-title>
      Simulação
    </v-card-title>
    <v-card-text>
      <detalhamento-rotas-table
          :items="items"
      />
    </v-card-text>
  </v-card>
  <v-card class="mt-4">
    <v-card-actions>
      <v-spacer />
      <v-btn
          color="primary"
          @click="$router.push('/fretes/simulador-fretes')"
      >
        <v-icon left>mdi-cancel</v-icon>
        cancelar
      </v-btn>
      <v-btn
          color="primary"
          @click="$emit('editarSimulacao')"
      >
        <v-icon left>mdi-pencil</v-icon>
        editar
      </v-btn>
      <v-btn
          color="primary"
          @click="$emit('descartarSimulacao')"
      >
        <v-icon left>mdi-plus</v-icon>
        novo
      </v-btn>
    </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import DetalhamentoRotasTable from '@/view/pages/fretes/simulador-fretes/components/DetalhamentoRotasTable'

export default {
  components: {
    DetalhamentoRotasTable,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
